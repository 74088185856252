import { Method } from './enums';

export const AASSIGNMENT = {
    getById: ({ exercise_id }) => ({
        method: Method.GET,
        url: `/assignment/${exercise_id}`,
    }),
    getDetail: ({ assignment_id }) => ({
        method: Method.GET,
        url: `/assignment/detail/${assignment_id}`,
    }),
    search: ({ params }) => ({
        method: Method.GET,
        url: `/assignment/search${params ?? ""}`,
    }),
    create: ({ data }) => ({
        method: Method.POST,
        url: "/assignment",
        data,
    }),
    update: ({ assignmentId, data }) => ({
        method: Method.PUT,
        url: `/assignment/${assignmentId}`,
        data,
    }),
    start: ({ exercise_id }) => ({
        method: Method.POST,
        url: `/assignment/${exercise_id}/start`,
    }),
    comment: ({ gradingId, data }) => ({
        method: Method.POST,
        url: `/assignment/${gradingId}/comment`,
        data,
    }),
    save: ({ gradingId, data }) => ({
        method: Method.POST,
        url: `/assignment/${gradingId}/save`,
        data,
    }),
    submit: ({ gradingId, data }) => ({
        method: Method.POST,
        url: `/assignment/${gradingId}/submit`,
        data,
    }),
    delete: ({ assignmentId }) => ({
        method: Method.DELETE,
        url: `/assignment/${assignmentId}`,
    }),
    submitExercise: ({ data }) => ({
        method: Method.POST,
        url: "/exercise-results",
        data,
    }),
    getBySubmitted: ({ status }) => ({
        method: Method.GET,
        url: `/assignment/student-by-submitted?isSubmitted=${status}`,
    }),
    getScoreByUser: ({ params }) => ({
        method: Method.GET,
        url: `/score/user/${params}`,
    }),
    getGradingByAssignment: ({ assignmentId }) => ({
        method: Method.GET,
        url: `/assignment/${assignmentId}/grading`,
    }),
    getGradingByStudentId: ({ assignmentId, studentId }) => ({
        method: Method.GET,
        url: `/assignment/${assignmentId}/grading/${studentId}`,
    }),
    getGradingDetail: ({ assignmentId, gradingId }) => ({
        method: Method.GET,
        url: `assignment/${assignmentId}/grading-detail/${gradingId}`,
    }),
    searchByParams: ({ params }) => ({
        method: Method.GET,
        url: `/items/search${params}`,
    }),
    scoreTestOffline: ({ data }) => ({
        method: Method.POST,
        url: `/score/test/offline`,
        data,
    }),
    getScoreByClass: ({assignmentId}) => ({
        method: Method.GET,
        url: `/score/class/assignment/${assignmentId}`
    })
};
