import { Method } from "./enums";

export const AAUTH = {
    login: ({ data }) => ({
        method: Method.POST,
        url: "/auth/sign-in",
        data: data,
    }),
    loginSSO: ({ data }) => ({
        method: Method.POST,
        url: "/auth/sso/sign-in",
        data: data,
    }),
    getProfile: () => ({
        method: Method.GET,
        url: "/profile",
    }),
    getAccessToken: ({ session_id }) => ({
        method: Method.GET,
        url: `/auth/sso/access-token?session_id=${session_id}`,
    }),
    forgotPassword: ({ data }) => ({
        method: Method.POST,
        url: "auth/forgot-password",
        data: data,
    }),
    checkEmail: ({ username }) => ({
        method: Method.GET,
        url: `users/email?username=${username}`,
    }),
    setNewPassword: ({ data }) => ({
        method: Method.POST,
        url: "auth/reset-password",
        data: data,
    }),
    verifyEmail: ({ data }) => ({
        method: Method.POST,
        url: "auth/confirm-field",
        data: data,
    }),
    tracking: ({ data }) => ({
        method: Method.POST,
        url: "/tracking-user",
        data,
    }),
    getTrackingByUser: ({ studentId, timeRange, type }) => ({
        method: Method.GET,
        url: `/tracking-user/user/search?user_id=${studentId}&type=${type}&fromDate=${timeRange.from}&toDate=${timeRange.to}`,
    }),
    getTracking: ({ studentId, timeRange, type }) => ({
        method: Method.GET,
        url: `/tracking-user/user/search?type=${type}&fromDate=${timeRange.from}&toDate=${timeRange.to}`,
    }),
    updateProfile: ({ data }) => ({
        method: Method.PUT,
        url: "/profile",
        data,
    }),
    updatePassword: ({ data }) => ({
        method: Method.POST,
        url: "/profile/change-password",
        data,
    }),
    addEmail: ({ data }) => ({
        method: Method.POST,
        url: "/auth/add-email",
        data,
    }),
    adminResetPassword: ({data}) => ({
        method: Method.POST,
        url: '/admin/users/set-password',
        data
    })
};
