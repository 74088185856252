import React, { useState, useCallback, useMemo, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Table, Button, Modal, Typography } from "antd";
import moment from "moment";
import { CommentOutlined } from "@ant-design/icons";
import { StoreContext } from "../../stores";
import { useTranslation } from "react-i18next";
import { cacheQueryConfig, handleFetchApi } from "../../configs/apiRequest";
import NoData from "../NoData";
import Report from "../Report";
import { AREPORT } from "../../apis/report";

function ReportHistory({ initData, params }) {
    const { me } = useContext(StoreContext);
    const { t } = useTranslation();
    const [modalState, setModalState] = useState({ isOpen: false, selectedReport: initData });

    const { data: reportHistory, isLoading } = useQuery(
        [params],
        async () => {
            const response = await handleFetchApi(AREPORT.search({ params }));
            return response?.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        },
        cacheQueryConfig({ time: 3 })
    );

    useEffect(() => {
        setModalState((prev) => ({ ...prev, selectedReport: initData }));
    }, [initData]);

    const openModal = (record) => {
        setModalState({ isOpen: true, selectedReport: record });
    };

    const closeModal = () => {
        setModalState({ isOpen: false });
    };

    const columns = [
        {
            title: t("date"),
            dataIndex: "date",
            key: "date",
            render: (date, record) => moment(record?.created_at).format("DD/MM/YYYY"),
        },
        {
            title: t("content"),
            dataIndex: "content",
            key: "content",
            render: (text) => <Typography.Text>{text?.slice(0, 30)}...</Typography.Text>,
        },
        {
            title: t("discipline_status"),
            dataIndex: ["class_report_status", "discipline"],
            key: "discipline",
            render: (value) => t(value),
        },
        {
            title: t("study_status"),
            dataIndex: ["class_report_status", "study"],
            key: "study",
            render: (value) => t(value),
        },
        {
            title: t("status"),
            dataIndex: "is_confirmed",
            key: "is_confirmed",
            render: (value, record) => (
                <Typography.Text type={value ? "success" : "warning"}>
                    {value ? "Đã xác nhận" : "Chờ xác nhận"} &nbsp;
                    {record?.admin_comment && <CommentOutlined style={{ color: "#F43F5E" }} />}
                </Typography.Text>
            ),
        },
        {
            title: t("actions"),
            key: "actions",
            render: (_, record) => (
                <Button type="link" onClick={() => openModal(record)}>
                    {t("view")}
                </Button>
            ),
        },
    ];

    const modalTitle = useMemo(
        () => (modalState.selectedReport?.date ? moment(modalState.selectedReport.date).format("DD/MM/YYYY") : t("report_detail")),
        [modalState.selectedReport, t]
    );

    return (
        <div>
            <Typography.Title level={4}>Lịch sử</Typography.Title>
            <Table
                columns={columns}
                dataSource={reportHistory}
                loading={isLoading}
                rowKey="id"
                locale={{
                    emptyText: <NoData imageWidth={70} text={t("no_data")} />,
                }}
            />
            <Modal centered={true} ariaHidden="false" title={modalTitle} open={modalState?.isOpen} onCancel={closeModal} footer={null} width="90dvw">
                <Report close={closeModal} footer={true} mode="VIEW" initData={modalState?.selectedReport} />
            </Modal>
        </div>
    );
}

export default React.memo(ReportHistory);
