import React, { useContext } from "react";
import { StoreContext } from "../../stores";
import { UserEnums } from "../../configs/enums";
import { Layout } from "antd";
import AdminReport from "./Admin";
import TeacherReport from "./Teacher";
import StudentReport from "./Student";

function ClassReport() {
    const {
        me: { role },
    } = useContext(StoreContext);

    const renderByRole = () => {
        switch (role) {
            case UserEnums.ADMIN:
            case UserEnums.SCHOOL_ADMIN:
                return <AdminReport />;

            case UserEnums.TEACHER:
            case UserEnums.HOME_TEACHER:
                return <TeacherReport />

            case UserEnums.STUDENT:
                return <StudentReport />

            default:
                break;
        }
    };

    return renderByRole();
}

export default ClassReport;
