import axios from "axios";
import Cookies from "js-cookie";
import { returnLogin } from "../utils";

const isDevMode = window.location.href?.includes("localhost") || window.location.href.includes("staging");

const api = axios.create({
    baseURL: `https://${isDevMode ? "staging." : ""}api.cloudbook.vn/api/v1`,
});

const AUTHENTICATION_FAIL = ["UR0015", "UR0014", "UR0013", "UR0012", "UR0001"];

api.interceptors.request.use(
    (config) => {
        const accessToken = Cookies.get("access_token");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.data?.code === "UR0015") {
            try {
                const refreshToken = Cookies.get("refresh_token");
                const { data } = await axios.post("/auth/token/refresh", { refresh_token: refreshToken });

                Cookies.set("access_token", data.access_token);
                Cookies.set("refresh_token", data.refresh_token);

                originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
                return api(originalRequest);
            } catch (refreshError) {
                console.error("Refresh token error", refreshError);
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

/**
 * Returns configuration object for React Query caching
 * @param {Object} params - Configuration parameters
 * @param {number} params.time - Cache duration in minutes (defaults to 3)
 * @returns {Object} Cache configuration with:
 * - staleTime: Time until data is considered stale (in ms)
 * - cacheTime: Time to keep unused data in cache (in ms)
 * - keepPreviousData: Keep showing previous data while fetching
 * - refetchOnWindowFocus: Disable refetch when window regains focus
 */
export const cacheQueryConfig = ({ time }) => ({
    staleTime: (time ?? 3) * 60000,
    cacheTime: (time ?? 3) * 60000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

/**
 * Validates if an API response was successful
 * @param {Object} response - API response object
 * @param {string} response.status - Status code from response
 * @returns {boolean} True if status code indicates success, false otherwise
 */
export const validResponse = ({ response }) => {
    const SUCCESS_STATUS = ["S0000", "S0001"];
    if (SUCCESS_STATUS?.includes(response?.status)) return true;
    return false;
};

export const handleFetchApi = async ({ method = "GET", url, data = null }) => {
    if (!url) throw new Error("No URL endpoint!");

    try {
        const response = await api({ method, url, data });
        if (AUTHENTICATION_FAIL.includes(response?.data?.status)) {
            returnLogin();
        }
        return response.data;
    } catch (error) {
        console.error("API call error", error);
        if (error.code === "ERR_NETWORK") {
            // window.location = "/error-page";
        } else if (error.code === "ERR_BAD_REQUEST" && !AUTHENTICATION_FAIL.includes(error.response?.data?.code)) {
            // returnLogin()

            throw error; // Re-throw the error for proper error handling in the calling function
        }
    }
};
