import { UserEnums } from "./enums";

const permissionCache = new Map();
export const ACTIONS = {
    READ: "READ",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    COMMENT: "COMMENT",
    DELETE: "DELETE",
};

// Permission map defining allowed actions for each user role
const PERMISSIONS = {
    [UserEnums.ADMIN]: {
        classReport: [ACTIONS.READ, ACTIONS.DELETE, ACTIONS.UPDATE, ACTIONS.COMMENT],
        assignment: [ACTIONS.READ, ACTIONS.DELETE],
    },
    [UserEnums.SCHOOL_ADMIN]: {
        classReport: [ACTIONS.READ, ACTIONS.DELETE, ACTIONS.UPDATE, ACTIONS.COMMENT],
        assignment: [ACTIONS.READ, ACTIONS.DELETE],
    },
    [UserEnums.TEACHER]: {
        classReport: [ACTIONS.CREATE, ACTIONS.READ, ACTIONS.UPDATE],
        assignment: [ACTIONS.CREATE, ACTIONS.READ, ACTIONS.UPDATE, ACTIONS.DELETE],
    },
    [UserEnums.HOME_TEACHER]: {
        classReport: [ACTIONS.CREATE, ACTIONS.READ, ACTIONS.UPDATE],
        assignment: [ACTIONS.CREATE, ACTIONS.READ, ACTIONS.UPDATE, ACTIONS.DELETE],
    },
    [UserEnums.STUDENT]: {
        classReport: [ACTIONS.READ],
        assignment: [ACTIONS.READ],
    },
};

/**
 * Checks if a user role has permission to perform an action on a feature
 * @param {Object} params - The parameters object
 * @param {string} params.role - User role to check permissions for
 * @param {string} params.key - Feature key to check permissions on
 * @param {string} params.action - Action type to check permission for
 * @returns {boolean} Whether the role has permission for the action
 */
export const hasPermission = ({ role, key, action }) => {
    if (!PERMISSIONS[role]) {
        console.warn(`Role "${role}" is not defined in permissions.`);
        return false;
    }
    if (!PERMISSIONS[role][key]) {
        console.warn(`Feature "${key}" is not defined for role "${role}".`);
        return false;
    }
    const cacheKey = `${role}-${key}-${action}`;
    if (permissionCache.has(cacheKey)) {
        return permissionCache.get(cacheKey);
    }
    const result = PERMISSIONS[role]?.[key]?.includes(action) || false;
    permissionCache.set(cacheKey, result);
    return result;
};
