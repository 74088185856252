import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Typography } from "antd";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import Report from "../../../components/Report";
import { StoreContext } from "../../../stores";
import ReportHistory from "../../../components/ReportHistory";
import bad from "../../../assets/images/bad.png";
import { GetReport } from "../fetch";

const { Content } = Layout;

function StudentReport() {
    const {
        me: { role, schoolClasses },
    } = useContext(StoreContext);
    const { t } = useTranslation();
    const today = moment().format("YYYY-MM-DD");

    const { data: reportData, isLoading } = useQuery(
        ["student_report", today],
        async () => {
            const response = await GetReport({ role, date: today, classId: schoolClasses[0]?.id });
            console.log(response);

            return response?.data[0];
        },
        {
            staleTime: 300000, // 5 minutes
            cacheTime: 300000,
            refetchOnWindowFocus: false,
        }
    );

    return (
        <Content>
            <div className="page student_report">
                <br />
                <Typography.Title level={4} className="flex__between__center">
                    {t("date")} {moment().format("DD/MM/YYYY")}
                </Typography.Title>
                {isLoading ? (
                    <Loading />
                ) : reportData ? (
                    <Report mode="VIEW" initData={reportData} />
                ) : (
                    <NoData img={bad} imageWidth={100} text={t("no_comments_today")} />
                )}
                <ReportHistory params={`?class_id=${schoolClasses[0]?.id}`} />
            </div>
        </Content>
    );
}

export default StudentReport;
