import { Method } from './enums';

export const ANOTIFICATION = {
    getNoti: ({ page }) => ({
        method: Method.GET,
        url: `/notification?page=${page}&size=10`
    }),
    readAll: () => ({
        method: Method.PUT,
        url: `/notification/read/all`
    }),
    readOnly: ({id}) => ({
        method: Method.PUT,
        url: `/notification/read/${id}`
    })
};
