import { fixedNumber } from "../../utils/validation";

export const suggestCols = [
    {
        title: "Nề nếp/ Thái độ",
        dataIndex: "attitude",
        key: "attitude",
    },
    {
        title: "Học tập",
        dataIndex: "study",
        key: "study",
    },
    {
        title: "Sự tiến bộ",
        dataIndex: "progress",
        key: "progress",
    },
    {
        title: "Tính chủ động",
        dataIndex: "initiative",
        key: "initiative",
    },
    {
        title: "Tinh thần đoàn kết",
        dataIndex: "teamSpirit",
        key: "teamSpirit",
    },
];

export const suggestGood = [
    {
        key: "1",
        attitude: "Học sinh luôn tuân thủ tốt nội quy lớp học và có thái độ nghiêm túc trong mọi hoạt động.",
        study: "Học sinh luôn hoàn thành tốt các bài tập và có nhiều đóng góp tích cực trong giờ học.",
        progress: "Học sinh đã có sự tiến bộ rõ rệt trong việc hoàn thành bài tập và hiểu bài học nhanh hơn.",
        initiative: "Học sinh luôn chủ động tìm kiếm tài liệu học tập và đặt câu hỏi để mở rộng kiến thức.",
        teamSpirit: "Học sinh luôn sẵn lòng giúp đỡ bạn bè và tạo môi trường học tập hòa đồng, vui vẻ.",
    },
    {
        key: "2",
        attitude: "Thái độ học tập của học sinh rất gương mẫu, luôn đúng giờ và có tinh thần tự giác cao.",
        study: "Kỹ năng làm việc nhóm của học sinh rất tốt, luôn hỗ trợ và chia sẻ với các bạn.",
        progress: "Khả năng tư duy và giải quyết vấn đề của học sinh đã cải thiện đáng kể so với trước đây.",
        initiative: "Học sinh có tinh thần tự giác cao, luôn tự nguyện tham gia các hoạt động học tập và ngoại khóa.",
        teamSpirit: "Học sinh có tinh thần đoàn kết cao, biết hợp tác với các bạn để hoàn thành tốt nhiệm vụ chung.",
    },
    {
        key: "3",
        attitude: "Học sinh thể hiện sự tôn trọng và hợp tác tốt với thầy cô và bạn bè trong lớp.",
        study: "Học sinh có khả năng tiếp thu kiến thức nhanh và áp dụng vào thực tiễn một cách hiệu quả.",
        progress: "Học sinh thể hiện sự cố gắng không ngừng và đã tiến bộ vượt bậc trong kết quả học tập.",
        initiative: "Học sinh thể hiện tính chủ động trong việc giải quyết bài tập khó mà không cần nhờ đến sự trợ giúp.",
        teamSpirit: "Sự gắn kết và hỗ trợ của học sinh với các bạn trong lớp là yếu tố quan trọng giúp lớp phát triển mạnh mẽ.",
    },
    {
        key: "4",
        attitude: "Học sinh luôn giữ gìn vệ sinh lớp học và có ý thức bảo vệ môi trường xung quanh.",
        study: "Học sinh luôn tích cực tham gia vào các hoạt động thảo luận và thể hiện sự hiểu biết sâu rộng về bài học.",
        progress: "Sự tiến bộ của học sinh trong cách học và thái độ học tập đáng được khen ngợi.",
        initiative: "Học sinh luôn chủ động làm việc nhóm, hỗ trợ và hướng dẫn các bạn khác khi cần thiết.",
        teamSpirit: "Học sinh luôn khuyến khích các bạn cùng tham gia hoạt động nhóm, tạo ra tinh thần đồng đội tốt.",
    },
    {
        key: "5",
        attitude: "Học sinh có ý thức tự quản cao, biết giúp đỡ các bạn trong việc duy trì nề nếp lớp học.",
        study: "Học sinh có tinh thần học hỏi cao, luôn cố gắng cải thiện kết quả học tập từng ngày.",
        progress: "Học sinh đã cải thiện rõ rệt sự tự tin khi thuyết trình và trình bày ý kiến của mình.",
        initiative: "Học sinh biết lập kế hoạch học tập hợp lý và tự quản lý thời gian rất tốt.",
        teamSpirit: "Tinh thần đoàn kết của học sinh giúp các bạn trong lớp cảm thấy thoải mái và gắn bó hơn.",
    },
    {
        key: "6",
        attitude: "Học sinh ổn định nhanh, vào lớp đúng giờ.",
        study: "Phần lớn học sinh nắm được kiến thức tốt, áp dụng thực hành vào các hoạt động nhóm trên lớp.",
        progress: "",
        initiative: "",
        teamSpirit: "",
    },
];

export const suggestBad = [
    {
        key: "1",
        attitude: "Học sinh cần chú ý hơn đến việc tuân thủ nội quy lớp học và giữ trật tự trong giờ học.",
        study: "Học sinh nên dành nhiều thời gian hơn để làm bài tập và ôn lại kiến thức đã học trên lớp.",
        progress: "Mặc dù đã có chút tiến bộ, nhưng học sinh cần nỗ lực nhiều hơn để đạt kết quả cao hơn.",
        initiative: "Học sinh cần chủ động hơn trong việc hỏi thầy cô hoặc bạn bè khi chưa hiểu bài, tránh thụ động.",
        teamSpirit: "Học sinh cần hợp tác hơn với các bạn trong lớp, tránh làm việc riêng lẻ mà không chia sẻ ý kiến với nhóm.",
    },
    {
        key: "11",
        attitude: "Thái độ học tập của học sinh chưa thật sự nghiêm túc, học sinh nên tập trung hơn để đạt hiệu quả cao hơn.",
        study: "Học sinh cần tham gia tích cực hơn trong các giờ thảo luận để cải thiện kỹ năng làm việc nhóm.",
        progress: "Học sinh cần kiên trì hơn trong việc rèn luyện kỹ năng và không nên dễ dàng bỏ cuộc khi gặp khó khăn.",
        initiative: "Học sinh chưa thực sự chủ động trong việc tự học và cần cố gắng lập kế hoạch học tập chi tiết hơn.",
        teamSpirit: "Học sinh cần học cách lắng nghe và tôn trọng ý kiến của các bạn trong các hoạt động nhóm.",
    },
    {
        key: "2",
        attitude: "Học sinh cần cải thiện việc tham gia các hoạt động chung và thể hiện tinh thần trách nhiệm với lớp.",
        study: "Học sinh cần chú ý hơn trong giờ giảng bài, tránh phân tâm bởi các yếu tố xung quanh.",
        progress: "Sự tiến bộ của học sinh còn chậm, cần đề ra kế hoạch học tập cụ thể hơn để đạt hiệu quả tốt hơn.",
        initiative: "Học sinh cần học cách tự quản lý thời gian và không dựa dẫm quá nhiều vào sự hướng dẫn của thầy cô.",
        teamSpirit: "Tinh thần đoàn kết của học sinh còn yếu, cần cải thiện để tạo môi trường học tập tốt hơn cho cả lớp.",
    },
    {
        key: "3",
        attitude: "Học sinh cần nhớ giữ gìn vệ sinh lớp học và không để các đồ dùng cá nhân lộn xộn.",
        study: "Kết quả học tập của học sinh còn chưa đạt yêu cầu, cần nỗ lực hơn để cải thiện.",
        progress: "Học sinh cần chủ động hơn trong việc nhận biết điểm yếu của mình và khắc phục.",
        initiative: "Học sinh cần tự giác hơn trong việc tham gia các hoạt động học tập, thay vì chờ đợi sự nhắc nhở.",
        teamSpirit: "Học sinh nên tích cực tham gia vào các hoạt động tập thể và không nên tách mình khỏi tập thể.",
    },
    {
        key: "4",
        attitude: "Học sinh cần điều chỉnh thái độ hợp tác với bạn bè, tránh gây mâu thuẫn và xích mích không cần thiết.",
        study: "Học sinh cần hạn chế việc sao chép bài tập từ các bạn khác, hãy cố gắng tự mình hoàn thành.",
        progress: "Học sinh nên cố gắng duy trì sự tiến bộ đã có và không được chủ quan trong học tập.",
        initiative: "Học sinh nên chủ động hơn trong việc tìm kiếm tài liệu và mở rộng kiến thức ngoài sách giáo khoa.",
        teamSpirit: "Học sinh cần học cách làm việc chung với các bạn để hoàn thành nhiệm vụ một cách hiệu quả hơn.",
    },
];

export const renderScoreDetailByTemplateId = ({ templateId = 1, row, testName }) => {
    const configs = {
        test: testName || 'Cuối kì I',
        year: '2024-2025'
    };

    switch (templateId) {
        case 1:
            return {
                username: row[0],
                name: row[1],
                mark: fixedNumber(row[2]),
                listening_score: fixedNumber(row[3]) || 0,
                speaking_score: fixedNumber(row[4]) || 0,
                reading_score: fixedNumber(row[5]) || 0,
                writing_score: fixedNumber(row[6]) || 0,
                year: configs.year
            };
        // DUBBING-mid-term-project
        case 2:
            return {
                username: row[0],
                name: row[1],
                group: row[2] || 1,
                project: row[3],
                final_score: fixedNumber(row[4]) || 0,
                mark: fixedNumber(row[4]) || 0,
                overall_score: fixedNumber(row[5]) || 0,
                creativity_score: fixedNumber(row[6]) || 0,
                grammar_score: fixedNumber(row[7]) || 0,
                pronunciation_score: fixedNumber(row[8]) || 0,
                fluency_score: fixedNumber(row[9]) || 0,
                comment1: row[10],
                comment2: row[11],
                // comment3: row[12],
                comment4: row[13],
                test: configs.test,
                year: configs.year
            };
        // LSRW-mid-term-project
        case 3:
            return {
                group: row[0] || 1,
                username: row[1],
                name: row[2],
                listening_score: fixedNumber(row[3]) || 0,
                speaking_score: fixedNumber(row[4]) || 0,
                reading_score: fixedNumber(row[5]) || 0,
                writing_score: fixedNumber(row[6]) || 0,
                mark: fixedNumber(row[7]) || 0,
                final_score: fixedNumber(row[7]) || 0,
                comment1: row[8],
                comment2: row[9],
                comment3: row[10],
                comment4: row[11],
                test: configs.test,
                year: configs.year
            };
        // PRESENTATION-mid-term-project
        case 5:
            return {
                username: row[0],
                name: row[1],
                group: row[2] || 1,
                avg_score: fixedNumber(row[3]) || 0,
                mark: fixedNumber(row[3]) || 0,
                overall_score: fixedNumber(row[4]) || 0,
                management_score: fixedNumber(row[5]) || 0,
                communication_score: fixedNumber(row[6]) || 0,
                comment: row[7],
                test: configs.test,
                year: configs.year
            };
        // Mind-map
        case 4:
            return {
                username: row[0],
                name: row[1],
                final_score: fixedNumber(row[2]) || 0,
                mark: fixedNumber(row[2]) || 0,
                presentation_score: fixedNumber(row[3]) || 0,
                presentation_comment: row[4],
                image_score: fixedNumber(row[5]) || 0,
                image_comment: row[6],
                color_score: fixedNumber(row[7]) || 0,
                color_comment: row[8],
                comprehension_score: fixedNumber(row[9]) || 0,
                comprehension_comment: row[10],
                language_score: fixedNumber(row[11]) || 0,
                language_comment: row[12],
                test: configs.test,
                year: configs.year
            };
        // MATH
        case 6:
        case 7:
        case 8:
            return {
                username: row[0],
                name: row[1],
                mark: fixedNumber(row[2]),
                test: configs.test,
                year: configs.year
            };
        case 9:
            return {
                username: row[0],
                name: row[1],
                mark: fixedNumber(row[6]),
                grammatical_score: fixedNumber(row[2]),
                lexical_score: fixedNumber(row[3]),
                pronunciation_score: fixedNumber(row[4]),
                fluency_score: fixedNumber(row[5]),
                final_score: fixedNumber(row[6]),
                comment1: row[7],
                comment2: row[8],
                comment3: row[9],
                comment4: row[10],
                test: configs.test,
                year: configs.year
            };

        default:
            return {};
    }
};
