import { AREPORT } from "../../apis/report";
import { handleFetchApi } from "../../configs/apiRequest";
import { UserEnums } from "../../configs/enums";
import { ACTIONS, hasPermission } from "../../configs/permissions";

const KEY = "classReport";

export const GetReport = ({ role, date, classId, courseId, teacherId }) => {
    if (!hasPermission({ role, key: KEY, action: ACTIONS.READ }) || !role) {
        console.warn(`Role "${role}" does not have READ permission for "${KEY}".`);
        return;
    }

    const ROLE_API_MAP = {
        [UserEnums.ADMIN]: AREPORT.search({ params: `?report_date=${date}` }),
        [UserEnums.SCHOOL_ADMIN]: AREPORT.searchByAdminSchool({ params: `?report_date=${date}` }),
        [UserEnums.TEACHER]: AREPORT.search({ params: `?teacher_id=${teacherId}&course_id=${courseId}&report_date=${date}` }),
        [UserEnums.HOME_TEACHER]: AREPORT.getByHomeTeacher({ params: `?report_date=${date}` }),
        [UserEnums.STUDENT]: AREPORT.search({ params: `?class_id=${classId}&report_date=${date}` }),
    };

    if (!ROLE_API_MAP[role]) {
        console.error(`No API handler defined for role: "${role}".`);
        return;
    }
    return handleFetchApi(ROLE_API_MAP[role]);
};

export const DELETE = async ({ role, reportId }) => {
    if (!hasPermission({ role, key: KEY, action: ACTIONS.DELETE })) return;
    return await handleFetchApi(AREPORT.delete({ reportId }));
};
